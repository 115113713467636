(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play-spela/assets/settings-selector/settings-selector-container.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play-spela/assets/settings-selector/settings-selector-container.js');
"use strict";


const {
  useMemo,
  useContext
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  ReactButton
} = svs.ui;
const {
  RadioButton
} = svs.ui.reactForm;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  CouponSettingsSelector
} = svs.components.tipsen.couponSettingsSelector;
const {
  useFilteredTipsinfoOptions,
  useFilteredOutcomeInfoChoises
} = svs.components.tipsen.tipsinfo;
const {
  selectModuleName,
  selectTipsinfoSupport
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  setOutcomeInfoChoises,
  setShowCompressedCoupon,
  setShowMatchAnalysesInCoupon,
  setShowMinistat,
  setTipsinfo
} = svs.components.tipsen.store.actions.couponSettingsActions;
const {
  selectShowCompressedCoupon,
  selectShowMatchAnalysesInCoupon,
  selectShowMinistatInfo
} = svs.components.tipsen.selectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  DialogContextual
} = svs.components.tipsen;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystemType
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const SettingsSelectorContainer = () => {
  const dispatch = useDispatch();
  const tipsinfoOptions = useFilteredTipsinfoOptions();
  const moduleName = useSelector(selectModuleName);
  const toggleAllSelectedState = useMemo(() => tipsinfoOptions.filter(option => option.isSelected).length === tipsinfoOptions.length, [tipsinfoOptions]);
  const outcomeInfoChoises = useFilteredOutcomeInfoChoises();
  const selectedOutcomeChoise = outcomeInfoChoises ? outcomeInfoChoises.find(el => el.isSelected) : {
    key: 'outcomeLabel'
  };
  const currentEngine = useSelector(selectEngineDefinition);
  const isGoalCount = currentEngine.outcomes.eventTypeId === EventTypeId.GOAL_COUNT;
  const showMinistat = useSelector(selectShowMinistatInfo);
  const showMatchAnalysesInCoupon = useSelector(selectShowMatchAnalysesInCoupon);
  const showCompressedCoupon = useSelector(selectShowCompressedCoupon);
  const isExtraSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const couponId = useCouponId();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const {
    pageType
  } = useContext(RenderContext);
  const supportedTipsInfo = useSelector(state => selectTipsinfoSupport(state, pageType));
  const shouldShowLableInCoupon = systemType !== SYSTEM_REDUCERA_FRITT && supportedTipsInfo.outcomeLabel;
  const onTipsinfoChange = (field, state) => {
    dispatch(setTipsinfo({
      moduleName,
      changes: [{
        field,
        state
      }]
    }));
  };
  const onToggleAllTipsinfo = () => {
    dispatch(setTipsinfo({
      moduleName,
      changes: tipsinfoOptions.map(_ref => {
        let {
          key
        } = _ref;
        return {
          field: key,
          state: !toggleAllSelectedState
        };
      })
    }));
  };
  const onToggleBetButtonDisplay = e => {
    var _e$currentTarget$valu, _e$currentTarget;
    const value = (_e$currentTarget$valu = (_e$currentTarget = e.currentTarget) === null || _e$currentTarget === void 0 ? void 0 : _e$currentTarget.value) !== null && _e$currentTarget$valu !== void 0 ? _e$currentTarget$valu : e;
    dispatch(setOutcomeInfoChoises({
      moduleName,
      changes: [{
        field: 'display',
        state: value
      }]
    }));
  };
  const onMinistatChange = show => {
    dispatch(setShowMinistat({
      moduleName,
      changes: [{
        field: 'show',
        state: show
      }]
    }));
  };
  const onMatchAnalysesChange = showMatchAnalyseInCouponOptions => {
    dispatch(setShowMatchAnalysesInCoupon({
      moduleName,
      showMatchAnalyseInCouponOptions
    }));
  };
  const handleHideAll = () => {
    const showMatchAnalyseInCouponOptions = {
      showAll: false,
      showAnalyseForecast: false,
      hideAll: true
    };
    onMatchAnalysesChange(showMatchAnalyseInCouponOptions);
  };
  const handleShowAll = () => {
    const showMatchAnalyseInCouponOptions = {
      showAll: true,
      showAnalyseForecast: false,
      hideAll: false
    };
    onMatchAnalysesChange(showMatchAnalyseInCouponOptions);
  };
  const handleShowAnalyseForecast = () => {
    const showMatchAnalyseInCouponOptions = {
      showAll: false,
      showAnalyseForecast: true,
      hideAll: false
    };
    onMatchAnalysesChange(showMatchAnalyseInCouponOptions);
  };
  const onCompressedCouponChange = show => {
    dispatch(setShowCompressedCoupon({
      moduleName,
      show
    }));
  };
  return React.createElement(React.Fragment, null, isExtraSmall && supportedTipsInfo.compressed && React.createElement(CouponSettingsSelector, null, React.createElement(CouponSettingsSelector.CouponSettingsHeading, null, "Kupong"), React.createElement(CouponSettingsSelector.CouponSettingsButtons, null, React.createElement(RadioButton, {
    checked: !showCompressedCoupon,
    key: "compressed-coupon-show-radio-group",
    name: "compressed-coupon-show-hide-radio-group",
    onChange: () => onCompressedCouponChange(false),
    value: "hide"
  }, "Standard"), !isGoalCount && React.createElement(RadioButton, {
    checked: showCompressedCoupon,
    key: "compressed-coupon-hide-radio-group",
    name: "compressed-coupon-show-hide-radio-group",
    onChange: () => onCompressedCouponChange(true),
    value: "show"
  }, "Kompakt"))), React.createElement("div", {
    className: "group-fieldsets".concat(isExtraSmall ? '--small-screen' : '--big-screen')
  }, shouldShowLableInCoupon && React.createElement(CouponSettingsSelector, null, React.createElement(CouponSettingsSelector.CouponSettingsHeading, null, "Tecken i knapp"), React.createElement(CouponSettingsSelector.CouponSettingsButtons, null, outcomeInfoChoises.map(_ref2 => {
    let {
      isSelected,
      title,
      key
    } = _ref2;
    const disabled = showCompressedCoupon && isExtraSmall && key === TipsinfoTypes.SvenskaFolket;
    return React.createElement(RadioButton, {
      checked: isSelected,
      disabled: disabled,
      key: key,
      onChange: onToggleBetButtonDisplay,
      value: key
    }, title);
  }))), supportedTipsInfo.ministat && React.createElement(CouponSettingsSelector, null, React.createElement(CouponSettingsSelector.CouponSettingsHeading, null, "Ministatistik"), React.createElement(CouponSettingsSelector.CouponSettingsButtons, null, React.createElement(RadioButton, {
    checked: showMinistat,
    key: "tipset-ministat-show-radio-group",
    name: "tipset-ministat-show-hide-radio-group",
    onChange: () => onMinistatChange(true),
    value: "show"
  }, "Visa"), React.createElement(RadioButton, {
    checked: !showMinistat,
    key: "tipset-ministat-hide-radio-group",
    name: "tipset-ministat-show-hide-radio-group",
    onChange: () => onMinistatChange(false),
    value: "hide"
  }, "D\xF6lj")))), supportedTipsInfo.analys && React.createElement(CouponSettingsSelector, null, React.createElement(CouponSettingsSelector.CouponSettingsHeading, null, "Spelanalyser"), React.createElement(CouponSettingsSelector.CouponSettingsButtons, null, React.createElement(RadioButton, {
    checked: showMatchAnalysesInCoupon.showAll,
    key: "tipset-match-analyses-show-radio-group",
    name: "tipset-match-analyses-show-hide-radio-group",
    onChange: () => handleShowAll(),
    value: "show"
  }, "Full analys"), React.createElement(RadioButton, {
    checked: showMatchAnalysesInCoupon.showAnalyseForecast,
    key: "tipset-match-analyses-prognos-radio-group",
    name: "tipset-match-analyses-show-hide-radio-group",
    onChange: () => handleShowAnalyseForecast(),
    value: "forecast"
  }, "Speltips"), React.createElement(RadioButton, {
    checked: showMatchAnalysesInCoupon.hideAll,
    key: "tipset-match-analyses-hide-radio-group",
    name: "tipset-match-analyses-show-hide-radio-group",
    onChange: () => handleHideAll(),
    value: "hide"
  }, "D\xF6lj"))), React.createElement(CouponSettingsSelector, null, React.createElement(CouponSettingsSelector.CouponSettingsHeading, null, "Tipsinfo", ' ', React.createElement(DialogContextual, null, "Med Tipsinfo v\xE4ljer du vilket typ av information som syns i kupongen")), React.createElement("div", {
    className: "pg_coupon_settings__tipsinfo_wrapper"
  }, (tipsinfoOptions === null || tipsinfoOptions === void 0 ? void 0 : tipsinfoOptions.length) > 1 && React.createElement(ReactButton, {
    className: "tipset-tips-info-btn",
    inverted: toggleAllSelectedState,
    onClick: onToggleAllTipsinfo,
    tabIndex: "0",
    thin: true,
    transparent: !toggleAllSelectedState
  }, "All info"), tipsinfoOptions === null || tipsinfoOptions === void 0 ? void 0 : tipsinfoOptions.map(_ref3 => {
    let {
      isSelected,
      title,
      key
    } = _ref3;
    return React.createElement(ReactButton, {
      "aria-checked": isSelected,
      className: "tipset-tips-info-btn",
      "data-testid": "test-id-".concat(key),
      disabled: systemType !== SYSTEM_REDUCERA_FRITT && key === selectedOutcomeChoise.key,
      inverted: isSelected,
      key: key,
      onClick: () => {
        onTipsinfoChange(key, !isSelected);
      },
      role: "checkbox",
      thin: true,
      transparent: !isSelected
    }, "+", ' ', title);
  }))));
};
setGlobal('svs.components.tipsen.routePlaySpela.SettingsSelectorContainer', SettingsSelectorContainer);

 })(window);